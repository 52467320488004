%light-color {
  color: var(--forest-green);
}

%dark-color {
  color: var(--white);
}

%light {
  @extend %light-color;
  background-color: var(--neutral-01);
}

%dark {
  @extend %dark-color;
  background-color: var(--forest-green);
}
