@import '../../styles/common';

.root {
  background-color: var(--white);
  bottom: 0;
  margin: 16px;
  max-width: 343px;
  position: fixed;
  right: 0;
  z-index: z-index(cookie-consent);
}
