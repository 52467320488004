@import '../../styles/common';

.inputBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.input {
  @include legal;
  border: none;
  border-radius: 4px;
  padding: 6px 8px;
  margin-top: 5px;
  font-size: 16px;

  .withLeftIcon & {
    padding-left: 36px;
  }

  .withRightIcon & {
    padding-right: 36px;
  }

  .lg & {
    padding: 14px 8px;
    margin-top: 8px;
  }
}

.leftIcon,
.rightIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 10px;
}

.leftIcon {
  left: 10px;
}

.rightIcon {
  right: 10px;
}
