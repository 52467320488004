@import './common';
@import './reset';
@import './fonts';

html {
  @extend .light;
  height: -webkit-fill-available;
}

body {
  @include body-r;
  @include webkit-fill-available(min-height);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.light {
  @extend %light;
}

.dark {
  @extend %dark;
}

header ~ main {
  padding-top: var(--header-height);
}

main {
  z-index: z-index(page);
}

.page {
  @extend %light;
  padding-bottom: 64px;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;

  h1 {
    margin-top: 64px;
  }

  p {
    @include mobile {
      max-width: calc(100vw - 48px);
    }
  }
}

.bold {
  font-weight: 600;
}

.nowrap {
  white-space: nowrap;
}

:root {
  /* config */
  --header-height: 53px;
  --devider: solid 1px currentColor;
  --button-height: 48px;

  /* colors */
  --forest-green: #0f3934;
  --white: #ffffff;
  --black: #000000;
  --black-02: #00000040;
  --beach-01-1: #f9e1ad;
  --beach-01-2: #f9e3b6;
  --beach-01-3: #eacaa3;
  --beach-01-4: #edcca3;
  --blue-01: #b0beee;
  --blue-02: #89a0f1;
  --blue-03: #718cec;
  --blue-04: #1e3897;
  --brown1-01: #a7394c;
  --brown1-02: #4d254c;
  --brown2-00: #ebb08f;
  --brown2-01: #dc8a5d;
  --brown2-02: #a76139;
  --brown2-03: #512b15;
  --green1-01: #c3ee67;
  --green1-02: #53af32;
  --green1-03: #1e5a48;
  --green1-04: #0b3c24;
  --green2-01: #96ee67;
  --green2-02: #32af63;
  --green2-03: #236370;
  --green2-04: #243064;
  --grey-01: #cfcfcf;
  --grey-02: #a0a0a0;
  --grey-03: #757575;
  --neutral-01: #f9f1e3;
  --neutral-02: #e7d3c8;
  --orange: #ffa666;
  --orange-01: #fea725;
  --purple-01: #e5b8fb;
  --purple-02: #c389f1;
  --purple-03: #945dec;
  --purple-04: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2d1377;
  --red: #e32612;
  --red-01: #e93631;
  --red-02: #c41b16;
  --whitesmoke: #f5f5f5;
  --overlay: rgba(0, 0, 0, 0.5);

  /* fonts */
  --h1: normal 540 28px/110% 'Brice';
  --h3: normal 540 20px/120% 'Brice';
  --body-r: normal 400 16px/150% 'Eina 01';
  --body-s: normal 400 14px/171% 'Eina 01';
  --body-xl: normal 420 22px/145% 'Brice';
  --labels: normal 400 14px/157% 'Eina 01';
  --text-links: normal 700 14px/143% 'Eina 01';
  --caption: normal 600 14px/164% 'Eina 01';
  --price-pill-xs: normal 600 14px/171% 'Eina 01';
  --price-s: normal 600 16px/120% 'Eina 01';
  --price-l: normal 600 20px/120% 'Eina 01';
  --legal: normal 400 12px/175% 'Eina 01';
  --headline-m: normal 540 30px/133% 'Brice';
  --headline-s: normal 540 24px/133% 'Brice';
  --headline-xs: normal 540 19px/147% 'Brice';
  --headline-l: normal 420 38px/113% 'Brice';
  --headline-l-bold: normal 540 38px/113% 'Brice';
  --headline-l-light: normal 420 38px/113% 'Brice';
  --headline-xl-bold: normal 660 42px/119% 'Brice';
  --subhead-l: normal 600 32px/119% 'Eina 01';
  --subhead-m: normal 600 27px/122% 'Eina 01';
  --subhead-s: normal 600 20px/140% 'Eina 01';
  --subhead-xs: normal 600 16px/150% 'Eina 01';
  --menu-item: normal 600 14px/110% 'Eina 01';
  --menu-option: normal 600 32px/119% 'Eina 01';
  --footer-links: normal 600 15px/170% 'Eina 01';
  --link-embedded-regular: normal 400 16px/150% 'Eina 01';
  --link-embedded-small: normal 400 13px/162% 'Eina 01';
  --button-labels: normal 600 15px/110% 'Eina 01';
  --all-caps-s: normal 600 11px/150% 'Eina 01';
  --body-emphasis-r: normal 600 20px/160% 'Eina 01';
  --body-emphasis-s: normal 600 16px/162% 'Eina 01';
  --eyebrow: normal 600 14px/110% 'Eina 01';
  --map-cluster: normal 600 16px/18px 'Eina 01';
  --video-title: normal 540 clamp(16px, 14vw, 110px) / 120% 'Brice';

  @include desktop {
    --button-height: 56px;

    --body-r: normal 400 20px/160% 'Eina 01';
    --body-s: normal 400 16px/162% 'Eina 01';
    --menu-overlay: normal 600 48px/150% 'Eina 01';
    --menu-item: normal 600 14px/110% 'Eina 01';
    --menu-option: normal 600 40px/120% 'Eina 01';
    --labels: normal 400 16px/138% 'Eina 01';
    --text-links: normal 700 15px/133% 'Eina 01';
    --headline-xs: normal 540 19px/147% 'Brice';
    --headline-s: normal 540 24px/133% 'Brice';
    --headline-m: normal 540 30px/133% 'Brice';
    --subhead-xs: normal 600 16px/131% 'Eina 01';
    --legal: normal 400 13px/162% 'Eina 01';
    --link-embedded-regular: normal 400 20px/160% 'Eina 01';
    --price-pill-xs: normal 600 15px/160% 'Eina 01';
  }

  /* transitions */
  --normal-duration: 0.4s;
}

/* For GTM */
[data-tag] {
  * {
    pointer-events: none;
  }
}
