$breakpoint: 768px;

@mixin sm-mobile {
  @media screen and (max-width: #{374px - 0.1}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$breakpoint - 0.1}) {
    @content;
  }
}

@mixin mobile-landscape {
  @media screen and (max-height: #{$breakpoint - 0.1}) and (orientation: landscape) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin webkit-fill-available($attr) {
  #{$attr}: 100vh;
  #{$attr}: -webkit-fill-available;
}

// typography
@mixin h1 {
  font: var(--h1);
}

@mixin h3 {
  font: var(--h3);
}

@mixin body-r {
  font: var(--body-r);
}

@mixin body-s {
  font: var(--body-s);
}

@mixin body-xl {
  font: var(--body-xl);
}

@mixin labels {
  font: var(--labels);
}

@mixin text-links {
  font: var(--text-links);
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: underline;
}

@mixin caption {
  font: var(--caption);
  text-transform: uppercase;
}

@mixin price-pill-xs {
  font: var(--price-pill-xs);
}

@mixin price-s {
  font: var(--price-s);
}

@mixin price-l {
  font: var(--price-l);
}

@mixin legal {
  font: var(--legal);
}

@mixin headline-l-bold {
  font: var(--headline-l-bold);
}

@mixin headline-l-light {
  font: var(--headline-l-light);
}

@mixin headline-xl-bold {
  font: var(--headline-xl-bold);
}

@mixin headline-l {
  font: var(--headline-l);
}

@mixin headline-m {
  font: var(--headline-m);
}

@mixin headline-s {
  font: var(--headline-s);
}

@mixin headline-xs {
  font: var(--headline-xs);
}

@mixin subhead-l {
  font: var(--subhead-l);
}

@mixin subhead-m {
  font: var(--subhead-m);
}

@mixin subhead-s {
  font: var(--subhead-s);
}

@mixin subhead-xs {
  font: var(--subhead-xs);
}

@mixin menu-item {
  font: var(--menu-item);
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

@mixin menu-option {
  font: var(--menu-option);
}

@mixin footer-links {
  font: var(--footer-links);
  font-feature-settings: 'liga' off;
}

@mixin link-embedded-regular {
  font: var(--link-embedded-regular);
  text-decoration-line: underline;
}

@mixin link-embedded-small {
  font: var(--link-embedded-small);
  text-decoration-line: underline;
}

@mixin button-labels {
  font: var(--button-labels);
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin all-caps-s {
  font: var(--all-caps-s);
  letter-spacing: 0.2em;
}

@mixin body-emphasis-r {
  font: var(--body-emphasis-r);
}

@mixin body-emphasis-s {
  font: var(--body-emphasis-s);
}

@mixin eyebrow {
  font: var(--eyebrow);
}

@mixin video-title {
  font: var(--video-title);
}
