@import '../../styles/common';

.toast {
  --info: var(--green1-03);
  --error: var(--red-01);
  background-color: var(--overlay);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: z-index(toast);

  &.error {
    bottom: 0;
  }
}

.container {
  border-radius: 4px;
  color: var(--white);
  gap: 8px;
  left: 8px;
  padding-left: 16px;
  position: absolute;
  right: 8px;
  top: 42px;

  @include desktop {
    gap: 16px;
    left: 16px;
    padding-left: 24px;
    right: 16px;
  }

  .info & {
    background-color: var(--info);
  }

  .error & {
    background-color: var(--error);
  }
}

.icon {
  background-color: var(--white);
  border-radius: 4px;
  height: 40px;
  width: 40px;

  .info & {
    color: var(--info);
  }

  .error & {
    color: var(--error);
  }
}
