@import '../../styles/common';

.root {
  background-color: var(--white);
  margin: 16px;
  max-width: 343px;
}

.actionButton {
  margin-top: 16px;

  @include desktop {
    margin-top: 32px;
  }
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  z-index: z-index(1);
}

.title {
  margin-right: calc(48px - 16px);
}
