@import '../../styles/common';

.button {
  -webkit-tap-highlight-color: transparent;
  border: 0;
  border-radius: 0;
  color: currentColor;
  background-color: transparent;
  padding: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
}

.buttonOnly {
  &:not([disabled]) {
    cursor: pointer;
  }
}

.linkOnly {
  &:not([aria-disabled='true']) {
    cursor: pointer;
  }
}

.left {
  text-align: left;
  justify-content: space-between;
}

.center-icon-right,
.center {
  text-align: center;
  justify-content: center;
}

.primary,
.secondary,
.tertiary {
  @include menu-item;
  border: solid 2px transparent;
  min-height: var(--button-height);

  &.center {
    padding: 10px 14px;
  }

  &.left {
    padding: 10px 23px;
  }
}

.primary {
  color: var(--white);
  background-color: var(--forest-green);

  &[disabled] {
    background-color: var(--grey-02);
  }

  :global(.dark) & {
    color: var(--forest-green);
    background-color: var(--white);

    &[disabled] {
      color: var(--grey-02);
    }
  }
}

.secondary {
  border-color: currentColor;

  &[disabled] {
    color: var(--grey-02);
  }
}

.link {
  @include text-links;
}

.icon {
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
}

.withIcon {
  max-width: 24px;
  max-height: 24px;

  .center & {
    margin-right: 8px;
  }

  .center-icon-right &,
  .left & {
    margin-left: 8px;
  }
}

.underline {
  text-decoration: underline;
}
