.text {
  opacity: 0;
  transform: translateY(var(--from));
  display: inline-block;
  white-space: pre;
}

.textWrapper {
  display: inline-block;
}

.wordsWrapper {
  display: flex;
  flex-wrap: wrap;
}
