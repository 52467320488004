@import '../../styles/common';

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlay);
  z-index: z-index(modal);
  @include mobile {
    padding: 0 24px;
  }
}

.modalBox {
  min-height: 200px;
  max-width: 343px;
  background-color: var(--white);
  z-index: z-index(modal-box);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}

.closeButton {
  position: absolute;
  background-color: var(--green1-03);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  top: -24px;
  right: -24px;
}

.title {
  text-align: center;
  padding: 24px 0 8px 0;
  border-bottom: var(--devider);
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
}

.content {
  word-break: break-word;
  overflow-y: auto;
  max-height: calc(90vh - 60px);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--grey-01);
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--forest-green);
  }
}
