/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/4a8591');

@font-face {
  font-family: 'Brice';
  font-weight: 420;
  src: url('../assets/fonts/brice/BriceLightSemiExpanded/font.woff2') format('woff2'),
    url('../assets/fonts/brice/BriceLightSemiExpanded/font.woff') format('woff');
}

@font-face {
  font-family: 'Brice';
  font-weight: 540;
  src: url('../assets/fonts/brice/BriceRegularSemiExpanded/font.woff2')
      format('woff2'),
    url('../assets/fonts/brice/BriceRegularSemiExpanded/font.woff') format('woff');
}

@font-face {
  font-family: 'Brice';
  font-weight: 660;
  src: url('../assets/fonts/brice/BriceSemiBoldSemiExpanded/font.woff2')
      format('woff2'),
    url('../assets/fonts/brice/BriceSemiBoldSemiExpanded/font.woff') format('woff');
}

@font-face {
  font-family: 'Eina 01';
  font-weight: 400;
  src: url('../assets/fonts/eina/Extratype\ -\ Eina\ 01\ Regular.otf')
    format('opentype');
}

@font-face {
  font-family: 'Eina 01';
  font-weight: 600;
  src: url('../assets/fonts/eina/Extratype\ -\ Eina\ 01\ SemiBold.otf')
    format('opentype');
}

@font-face {
  font-family: 'Eina 01';
  font-weight: 700;
  src: url('../assets/fonts/eina/Extratype\ -\ Eina\ 01\ Bold.otf') format('opentype');
}
