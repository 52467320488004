@import '../../styles/common';

.img {
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: opacity 0.5s 0.3s, transform 1s 0.3s ease-in-out;
  opacity: 0;
  object-fit: cover;
  object-position: 50% 50%;
  pointer-events: none;
  z-index: z-index(splash);
}

.visible {
  opacity: 1;
}
.open {
  transform: translateY(-100%);
}

.visible.open {
  pointer-events: auto;
}
