@import '../../styles/common';

.h1 {
  @include h1;
}

.h3 {
  @include h3;
}

.body-r {
  @include body-r;
}

.body-s {
  @include body-s;
}

.body-xl {
  @include body-xl;
}

.labels {
  @include labels;
}

.text-links {
  @include text-links;
}

.caption {
  @include caption;
}

.price-pill-xs {
  @include price-pill-xs;
}

.price-s {
  @include price-s;
}

.price-l {
  @include price-l;
}

.legal {
  @include legal;
}

.headline-l-bold {
  @include headline-l-bold;
}

.headline-l-light {
  @include headline-l-light;
}

.headline-xl-bold {
  @include headline-xl-bold;
}

.headline-l {
  @include headline-l;
}

.headline-m {
  @include headline-m;
}

.headline-s {
  @include headline-s;
}

.headline-xs {
  @include headline-xs;
}

.subhead-l {
  @include subhead-l;
}

.subhead-m {
  @include subhead-m;
}

.subhead-s {
  @include subhead-s;
}

.subhead-xs {
  @include subhead-xs;
}

.menu-item {
  @include menu-item;
}

.menu-option {
  @include menu-option;
}

.footer-links {
  @include footer-links;
}

.link-embedded-regular {
  @include link-embedded-regular;
}

.link-embedded-small {
  @include link-embedded-small;
}

.button-labels {
  @include button-labels;
}

.all-caps-s {
  @include all-caps-s;
}

.body-emphasis-r {
  @include body-emphasis-r;
}

.body-emphasis-s {
  @include body-emphasis-s;
}

.eyebrow {
  @include eyebrow;
}

.video-title {
  @include video-title;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
