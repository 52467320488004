@import '../../styles/common';

.view {
  --side-padding: 0px;
  display: grid;
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
}

.sm {
  --side-padding: 16px;

  @include desktop {
    --side-padding: 24px;
  }
}

.md {
  --side-padding: 24px;

  @include desktop {
    --side-padding: 42px;
  }
}

.lg {
  --side-padding: 24px;

  @include desktop {
    --side-padding: 80px;
  }
}

.x-lg {
  --side-padding: 40px;

  @include desktop {
    --side-padding: 96px;
  }
}

.absolute,
.fixed {
  height: calc(100% - var(--header-height));
  overflow: hidden;
  width: 100%;
  top: var(--header-height);
  left: 0;
}

.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}

.withBottomDevider {
  border-bottom: var(--devider);
}
