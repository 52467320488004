@import '../../styles/common';

$duration: 0.3s;

%transition {
  transition: transform $duration ease-out;
}

.logo {
  align-items: center;
  display: flex;
  height: var(--header-height);
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: color $duration;
  width: 100%;
  z-index: z-index(logo);

  a {
    pointer-events: auto;
  }
}

.figure {
  @extend %transition;

  align-items: center;
  display: flex;
  transform: translateX(13px);

  .withText & {
    transform: translateX(0);
  }

  @include sm-mobile {
    transform: scale(0.85);
  }
}

.left,
.right {
  overflow: hidden;
  svg {
    @extend %transition;

    .withText & {
      transform: translateX(0);
    }
  }
}

.left {
  margin-right: 6px;

  svg {
    transform: translateX(100%);
  }
}

.right {
  margin-left: 4px;

  svg {
    transform: translateX(-100%);
  }
}

.dark {
  @extend %dark-color;
}
